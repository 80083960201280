import React from "react"
import Layout from "../../components/layout"

import SEO from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import OmOssComponent from "../../components/om-oss/OmOssComponent"

export default function AboutUsPage() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 2717 }) {
        id
        title
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        translations {
          uri
        }
        omOss {
          huvudrubrikOmoss
          primartTextinnehallOmoss
          snabbFaktaOmoss
          snabbFaktaRubrikOmoss
          underrubrikOmoss
        }
      }
    }
  `)

  const page = data.wpPage
  const omOss = data.wpPage.omOss
  const featuredImage = data.wpPage.featuredImage
  const translation = data.wpPage.translations[0]?.uri

  return (
    <Layout lang="en" translation={translation}>
      <SEO title={page.title} />
      <>
        <OmOssComponent
          omOss={omOss}
          featuredImage={featuredImage}
          title={page.title}
          lang="en"
        />
      </>
    </Layout>
  )
}
